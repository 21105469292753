@font-face {font-family: AzonixRegular;src: url(assets/Azonix.otf);}
.particlesTest{
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #000;
  z-index: -1;
}
//Css variables:
$general-buttons: #007ac1;
$button-bg: #1c1c1c;
$borders: #707070;

//Products:
// $team-bicep: #00c853;
//Note: all disabled colors are chosen by material color picker. disabled value: original value - 200
$team-bicep: #2ab4ff;
$team-bicep-disabled: #82d3ff;
$body-tracker: #04c544;
$body-tracker-disabled: #71d881;

//Global

html,
body {
  height: 100%; /* background-color: #000; */
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  color: #fff;
  background-color: #000;
  max-width: 100%;
}
// Text
h1 {
  font-size: 15vw;
  padding: 0;
  margin: 0;
  font-family: AzonixRegular, Roboto,"Helvetica Neue",sans-serif ;
}
.slogan {
  font-size: 7vw;
  color: $general-buttons;
}
.App .Page{
  margin:0;
  padding:0;
  max-width: 100%;
  width: 100vw;
}
// Blocks

.section {
  max-width: 100%;
  width: 100vw;
  height: 100vh;
  
  max-height: 100vh;
  margin: 0;
}

h2 {
  font-size: 4vh;
}
p {
  font-size: 2vh;
}

//Title
#title {
  padding-top: 25vh;
  text-align: center;
}

//About
#about {
  padding: 20px 8vw;
  top: 0;
  left: 0;
  color: white;
  > img {
    max-width: 60%;
  }
  > p {
    text-align: center;
  }
}

//Products
#products {
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  padding-top: 10px;
  > h2 {
    padding-left: 10px;
    padding-top: 5%;
  }
  > p {
    padding-left: 10px;
  }
}

.product {
  margin: 0;
  margin-top: 10%;
  margin-left: 20%;
  width: 90%;
  height: 55vh;
  background-color: $button-bg;
  text-align: center;
  justify-content: center;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  button {
    height: 12%;
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0;
    font-size: 4vw;
    border:0;
  }
  > p {
    padding: 10px;
    padding-top: 0.2vh;
  }
  &.bodyTrackerItem {
    > .title {
      color: $body-tracker;
    }
  
    button {
      background-color: $body-tracker;
      &:enabled{
        cursor: pointer;
      }
      &:disabled{
        background-color: $body-tracker-disabled;
      }
    }
  }
  &.teamBicepItem {
    img{
      height: 30%;
      width: 70%;
    }
    > .title {
      color: $team-bicep;
    }
  
    button {
      background-color: $team-bicep;
      &:enabled{
        cursor: pointer;
      }
      &:disabled{
        background-color: $team-bicep-disabled;
      }
    }
  }
  .previewLogo {
    height: 40%;
    max-height: 50%;
    max-width: 100%;
    justify-content: center;
  }
}


//Support
.dialogParent{
  display: inline;
}
.supportDialog{
  background-color: rgb(20, 20, 20);
}
.socialPlatformTitle{
  margin-bottom: 9vh;
}
.IconBar {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, -100%);
  -moz-transform: translate(-50%, -100%);    /* Older Gecko browser */
  -ms-transform: translate(-50%, -100%);     /* IE9+ */
  -o-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
}
.SingleIcons {
  color: white;
  z-index: 9999;
  margin: 1%;
  max-width: 23%;
  height: 8vh;
}
.supportTBButtonLink{
  text-decoration: none;
}
.supportJoinTB {
  height: 10vh;
  background-color: white;
  width: 450px;
  max-width: 95%;
  margin: auto;
  border-radius: 5px;
  position: relative;
  margin-bottom:2vh;
  text-align: center;
  
  .supportJoinTBText {
    font-size: 1.2rem;
    margin-right: 10px;
    color: black;
    padding-top: 2vh;
    padding-right: 5vw;
    position: relative;
    top: 35%;
  }
  .ButtonIcons {
    position: absolute;
    top: 1vh;
    right: 2vw;
    height: 8vh;
  }
}
.altEmail {
  text-decoration: underline;
  color: white;
  cursor: pointer;
}


//services
#services {
  position: relative;
  height: 95%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  padding-top: 10px;
  > h2 {
    padding-left: 10px;
    padding-top: 5%;
  }
  > p {
    padding-left: 10px;
  }
}

#mainBlock {
  margin-top: 15%;
  width: 80%;
  margin-left: 10%;
  .link{
    text-decoration: none;
  }
  button {
    text-decoration: none;
    text-transform: capitalize;
    width: 45%;
    margin: 2.5%;
    font-size: 1.5vw;
  }
}

#footer{
  width: 100%;
  z-index: 999;
  color: rgba(255, 255, 255, 0.5);
  position: absolute;
  bottom: -25px;
  text-align: center;
  margin:0;
  overflow: hidden;
  font-size: 0.67em;
}

//Terms Of Service and Privacy Policy
.termsAndPrivacy {
  height: 100%;
  padding: 20px;
  background-color: rgb(18, 18, 18);
  p,
  h1,
  h2,
  ul,
  li {
    margin: 1% 2%;
    text-align: left;
  }
  a {
    color: white;
  }
  h1 {
    font-size: xx-large;
  }
  .TermAndPrivReturnLink{
    position: relative;
    display: inline;
  }
  .TermAndPrivReturnButton{

    margin-top:3%;
  }
}

//MEDIA QUERIES
//TODO: this is a little mess. might need some rework
@media screen and (max-width: 1199px) {
  //About
  #about {
    > img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 40vw;
      max-height: 20vh;
      position: relative;
    }
  }
  #products{
    .product {
      .title {
        padding-top: 5%;
        font-size: 3vh;
      }
    }
  }
}
@media (orientation: landscape) {
  //Global
  h1 {
    font-size: 15vh;
  }
  .slogan {
    font-size: 7vh;
  }
  h2 {
    font-size: 4vw;
  }
  p {
    font-size: 2vw;
  }
  //About
  #about {
    position: relative;
    > p {
      text-align: left;
    }
    > img {
      max-width: 40%;
      margin: 10px;
      max-height: 35vh;
      float: right;
    }
  }
  //Products
  #products {
    > h2 {
      padding-top: 1vh;
      margin: 2vh 0;
    }
  }
  swiper {
    margin-top: 0%;
  }
  .product {
    margin-top: 1%;
    margin-left: 40%;
    height: 60vh;
    max-height: 60vh;
    text-align: center;
    button {
      height: 12%;
      position: absolute;
      bottom: 0px;
      width: 100%;
      left: 0;
      font-size: 3.5vh;
    }
    > p {
      margin: 0;
    }
  }
  .teamBicepItem {
    > .title {
      color: $team-bicep;
    }

    button {
      background-color: $team-bicep;
    }
  }

  .title {
    padding-top: 2%;
    margin: 0;
    font-size: 3vw;
  }

  .previewLogo {
    height: 30vh;
    max-width: 100%;
    justify-content: center;
  }
  //Services
  #mainBlock {
    margin-top: 5%;
    button {
      font-size: 4vw;
    }
  }
}
@media screen and (max-height: 400px) {
  //fix for products
  .product {
    > p {
      display: none;
    }
  }
}
@media screen and (min-width: 1000px) and (min-width: 1200px) {
  //About
  #about {
    > p {
      text-align: left;
    }
  }
}
@media screen and (min-width: 1200px) {
  //Global
  h1 {
    font-size: 6vw;
  }
  .slogan {
    font-size: 3vw;
  }

  h2 {
    text-align: center;
    font-size: 3.5vh;
  }
  p {
    text-align: center;
    font-size: 2vh;
  }
  //About
  #about {
    position: relative;
    > p {
      max-width: 50%;
      width: 50%;
      padding: 20px;
      margin: 0;
      margin-top: 10px;
    }
    > img {
      float: right;
      height: 100%;
      margin-right: 5%;
      max-width: 40%;
      padding: 20px;
    }
  }
  //Products
  .product {
    margin-left: 40%;
    button {
      width: 20vw;
      left: 50%;
      margin-left: -10vw;
      font-size: 2.5vh;
      margin-bottom:2%;
      border-radius: 5px;
    }
    > p {
      padding: 40px 50px;
      font-size: 2vh;
    }
  }
  .title {
    padding-top: 2%;
    margin: 0;
    font-size: 2.5vw;
  }
  .teamBicepItem{
    img{
      height: 35%;
      margin-bottom: 0%;
      margin-top: 3%;
    }
  }

  //Services
  #mainBlock {
    button {
      font-size: 2vw;
    }
  }
  //Contact
  .mat-dialog-container {
    //global
    background-color: #000;
  }
  #supportJoinTB {
    h4 {
      font-size: 2vw;
    }
  }
}
@media screen and (max-width: 700px) {
  #mainBlock {
    button {
      width: 100%;
      margin: 0;
      margin-bottom: 8%;
      font-size: 6vw;
    }
  }
  #supportJoinTB {
    h4 {
      font-size: 5vw;
    }
  }
}